import axios from 'axios';

export default function () {
  const baseUrl = document.querySelector('meta[name=ax-base-url]').content;
  const apiKey = document.querySelector('meta[name=ax-team-token]').content;

  axios.defaults.baseURL = baseUrl;
  axios.defaults.headers.common.Authorization = `Bearer ${apiKey}`;
  axios.defaults.headers.common['Content-Type'] = 'application/json';
  axios.defaults.headers.common.Accept = 'application/json';
}
