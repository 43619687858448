<template>
  <div class="flex flex-col shadow-lg bg-white border border-gray-100 rounded-lg mt-16">
    <div class="p-6 text-center text-2xl font-bold rounded-t-lg"
         :style="{ backgroundColor: primaryColor, color: brandTextColor }">
      <h1>{{ teamName }}</h1>
    </div>
    <div>
      <div class="relative w-full h-1">
        <div class="absolute w-full h-full opacity-50" :style="{ backgroundColor: primaryColor }"></div>
      </div>
    </div>
    <div class="px-8 py-12 mx-auto">
      <div class="flex justify-between items-center cursor-pointer">
        <div>
          <div v-if="overdue || paid" class="mb-2">
            <span v-if="overdue" class="bg-red-200 text-red-900 uppercase tracking-wider px-2 py-1 rounded-sm font-medium text-sm">
              Past due
            </span>
            <span v-if="paid" class="bg-green-50 text-green-900 uppercase tracking-wider px-2 py-1 rounded-sm font-medium text-sm">
            Paid
            </span>
          </div>
          <h2 class="font-bold text-gray-900 text-3xl">{{ formatAsCurrency(invoice.amount_due) }}</h2>
          <p class="text-gray-500 font-medium text-sm mt-1" v-if="invoice.due_on">
            Due {{ formatWithMoment(invoice.due_on, 'MMM Do, YYYY') }}
          </p>
        </div>
        <div>
          <a :href="invoice.pdf" class="cursor-pointer" v-if="!paid">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-gray-400">
              <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m.75 12l3 3m0 0l3-3m-3 3v-6m-1.5-9H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
            </svg>
          </a>
          <a :href="invoice.receipt.cloudfront_url" class="cursor-pointer" v-if="paid && invoice.receipt">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-gray-400">
              <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m.75 12l3 3m0 0l3-3m-3 3v-6m-1.5-9H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
            </svg>
          </a>
        </div>
      </div>
      <div class="mt-10 flow-root">
        <table class="min-w-full">
          <tbody>
          <tr>
            <td class="text-gray-400 pr-8 align-top">To</td>
            <td class="text-gray-600 align-top" style="word-break: break-word;">
              <span v-if="previewOnly">
                {{ invoice.to_name }}
              </span>
              <span v-else>
              {{ invoice.billing_profile.email }}
              </span>
            </td>
          </tr>
          <tr>
            <td class="text-gray-400 pr-8 align-top">From</td>
            <td class="text-gray-600 align-top break-words">{{ teamName }}</td>
          </tr>
          <tr>
            <td class="text-gray-400 pr-8 align-top">Invoice</td>
            <td class="text-gray-600 align-top" style="word-break: break-word;">{{ invoice.number }}</td>
          </tr>
          <tr>
            <td class="text-gray-400 pr-8 align-top">Memo</td>
            <td class="text-gray-600 align-top break-words">{{ invoice.description }}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <div v-if="!paid">
        <div class="my-10 h-0.5 bg-gray-100 px-6"></div>
        <div class="-mt-4">
          <div v-for="item in invoice.invoice_items">
            <div class="flex justify-between mt-4">
              <div>
                <p class="text-base text-gray-600">
                  {{ item.description }}
                </p>
                <p class="text-sm text-gray-400">
                  Qty {{ item.quantity }} @ {{ formatAsCurrency(item.amount / item.quantity) }}
                </p>
              </div>
              <div class="text-base text-gray-600">
                {{ formatAsCurrency(item.amount) }}
              </div>
            </div>
          </div>
        </div>
        <div v-if="!previewOnly">
          <div class="my-10 h-0.5 bg-gray-100 px-6"></div>
          <div class="">
            <div>
              <ValidationObserver ref="observer" v-slot="{ handleSubmit } ">
                <div role="form" class="flex flex-col space-y-4">
                  <ValidationProvider name="name on card" rules="required" v-slot="{ errors }">
                    <div>
                      <label for="name_on_card" class="block text-sm font-medium text-gray-700">Name on card</label>
                      <div class="mt-1 relative rounded-md shadow-sm">
                        <input type="text"
                               name="name on card"
                               id="name_on_card"
                               v-model="nameOnCard"
                               :class="inputClasses(errors[0])">
                        <error-icon v-if="errors[0]"></error-icon>
                      </div>
                      <p class="mt-2 text-sm text-red-600" v-if="errors[0]">
                        {{ errors[0] }}
                      </p>
                    </div>
                  </ValidationProvider>
                  <ValidationProvider name="zip" rules="required" v-slot="{ errors }">
                    <div>
                      <label for="zip" class="block text-sm font-medium text-gray-700">ZIP</label>
                      <div class="mt-1 relative rounded-md shadow-sm">
                        <input type="text"
                               name="zip"
                               id="zip"
                               v-model="zip"
                               :class="inputClasses(errors[0])">
                        <error-icon v-if="errors[0]"></error-icon>
                      </div>
                      <p class="mt-2 text-sm text-red-600" v-if="errors[0]">
                        {{ errors[0] }}
                      </p>
                    </div>
                  </ValidationProvider>
                  <template v-if="justifiLoading">
                    <wrestler-icon :stroke-color="primaryColor" class="mx-auto"></wrestler-icon>
                  </template>
                  <justifi-card-form @cardFormReady="justifiLoading = false" validation-mode="onBlur"></justifi-card-form>
                </div>
                <button type="submit"
                        @click.prevent="handleSubmit(getToken)"
                        :disabled="paying || creatingToken"
                        class="flex justify-center space-x-4 mt-4 focus:outline-none w-full py-3 rounded-md font-semibold"
                        :style="{backgroundColor: primaryColor, color: brandTextColor }">
                  <span>Pay {{ formatAsCurrency(invoice.amount_due) }}</span>
                  <span v-if="paying || creatingToken">
                  <wrestler-icon stroke-color="white"></wrestler-icon>
                </span>
                </button>
                <p v-if="error" class="text-red-500 text-xs text-center py-2">{{ errorMessage }}</p>
              </ValidationObserver>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { currencyMix } from '../shared/currency_mix';
import { billingPartnerMix } from '../shared/billing_partner_mix';
import { errorableMix } from '../wrestling/vue/mixins/errorable_mix';
import WrestlerIcon from './wrestler-icon.vue';
import ErrorIcon from './error-icon.vue';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { Money } from 'v-money';
import { TheMask } from 'vue-the-mask';
import { formattingMixin } from '../wrestling/vue/mixins/formatting_mix';
import manual_invoice from '../wrestling/vue/manual_invoice.vue';

export default {
  name: "hosted-invoice-form",
  mixins: [currencyMix, billingPartnerMix, errorableMix, formattingMixin],
  components: {
    WrestlerIcon,
    ErrorIcon,
    ValidationObserver,
    ValidationProvider,
    Money,
    TheMask
  },
  props: {
    invoice: {
      type: Object,
      required: true,
    },
    teamName: {
      type: String,
      required: true
    },
    primaryColor: {
      type: String,
      required: true,
    },
    brandTextColor: {
      type: String,
      default: '#FFF'
    },
    successUrl: {
      type: String,
      required: true
    },
    previewOnly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      money: {
        decimal: '.',
        thousands: ',',
        prefix: '$ ',
        suffix: '',
        precision: 0,
        masked: false,
      },
      paying: false,
    }
  },
  mounted() {
    if (this.previewOnly) {
      let elRect = this.$el.getBoundingClientRect()
      window.parent.postMessage({
        type: 'resize-iframe',
        payload: {
          width: elRect.width,
          height: elRect.height
        }
      }, '*')
    }
  },
  computed: {
    manual_invoice() {
      return manual_invoice
    },
    paid() {
      return this.invoice.status === 'paid';
    },
    overdue() {
      return !this.paid && moment().isAfter(this.invoice.due_on, 'day')
    },
  },
  methods: {
    inputClasses(error) {
      const normalClasses = 'shadow-sm block w-full border-gray-300 rounded-md sm:text-sm';
      if (!error) {
        return normalClasses;
      }

      return `border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:ring-red ${normalClasses}`;
    },
    getToken() {
      this.getPaymentToken(this.nameOnCard, this.invoice.billing_profile.email, this.zip);
    },
    tokenized(token) {
      this.payInvoice(token);
    },
    payInvoice(token) {
      const vm = this;
      const url = vm.$apiService.payInvoiceUrl(this.invoice.token);
      if (vm.paying) {
        return;
      }
      const params = {
        payment_method: {
          type: "card",
          token: token,
        }
      }
      vm.paying = true;
      axios.post(url, params)
          .then((response) => {
            vm.paying = false;
            window.location.assign(vm.successUrl);
          })
          .catch((error) => {
            vm.errorMessage = `Error paying invoice ${error.toString()}`;
            vm.error = true;
            vm.paying = false;
          });
    }
  }
};
</script>
<style scoped>
</style>
