<template>
  <div class="flex flex-col shadow-lg bg-white border border-gray-100 rounded-lg">
    <div class="p-6 text-left text-2xl font-bold rounded-t-lg" :style="{ backgroundColor: primaryColor, color: brandTextColor }">
      <h2>{{ displayTitle }}</h2>
    </div>
    <div>
      <div class="relative w-full h-1">
        <div class="absolute w-full h-full opacity-50" :style="{ backgroundColor: primaryColor }"></div>
      </div>
    </div>
    <div class="mt-6 px-6" v-if="fundraiser.show_progress_bar">
      <div class="flex px-2">
        <div class="text-sm font-semibold" :style="{ color: primaryColor}">
          {{ moneyRaised }} raised
        </div>
        <div class="ml-auto text-sm text-gray-700">
          {{ goalAmount }} goal
        </div>
      </div>
      <div class="relative w-full h-6 rounded-full border mt-1" :style="{ borderColor: primaryColor }">
        <div class="z-0 absolute h-full rounded-full" :style="barWidthAndColor"></div>
      </div>
    </div>
    <div v-if="fundraiser.open">
      <template v-if="hasCustomTiers">
        <div class="mt-4 px-6">
          <template v-if="loading">
            <wrestler-icon class="mx-auto" :stroke-color="primaryColor"></wrestler-icon>
          </template>
          <template v-else>
            <div class="grid gap-2 grid-cols-1"
                 v-for="(group, name, index) in tiersByGroup">
              <div class="font-bold text-lg"
                   :class="{ 'mt-6': index !== 0 }"
                   v-if="name.length > 0 && name != 'null'">{{ name }}
              </div>
              <div v-for="tier in group"
                   v-bind:key="tier.id"
                   :class="priceClasses"
                   class="cursor-pointer col-span-1 rounded-md p-3 shadow-sm sm:text-md border text-gray-500 border-gray-500 focus:ring-pink-500 focus:border-pink-500"
                   :style="styleForTier(tier)"
                   @click="tierSelected(tier)"
              >
                <div class="flex justify-between items-center">
                  <div class="flex-col flex-initial">
                    <div class="text-xs font-semibold tracking-wide uppercase">
                      {{ tier.name }}
                    </div>
                    <div>
                      {{ `${formatAsCurrency(tier.amount, 0, 2)}` }}
                    </div>
                  </div>
                  <div v-if="tier.selected" class="flex-col w-1/4" @click.stop>
                    <label class="text-xs font-semibold tracking-wide uppercase">Quantity</label>
                    <input type="number"
                           v-model.number="tier.purchase_quantity"
                           class="shadow-sm block w-3/4 border-gray-300 rounded-md sm:text-sm" style="min-width: 60px;"/>
                  </div>
                  <div v-if="tier.sold_out" class="align-self-flex-end">
                    <p class="border p-1 text-sm font-medium uppercase tracking-wide -rotate-6 rounded-sm text-center" :style="{ borderColor: primaryColor, color: primaryColor }">
                      sold out
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
      </template>
      <template v-else>
        <div class="mt-4 grid gap-2 px-6 grid-cols-3">
          <template v-if="loading">
            <div class="mx-auto col-span-3">
              <wrestler-icon :stroke-color="primaryColor"></wrestler-icon>
            </div>
          </template>
          <template v-else>
            <div v-for="tier in tiers"
                 v-bind:key="tier.id"
                 :class="priceClasses"
                 class="cursor-pointer col-span-1 rounded-md p-3 shadow-sm sm:text-md border text-gray-500 border-gray-500 focus:ring-pink-500 focus:border-pink-500"
                 :style="styleForTier(tier)"
                 @click="tierSelected(tier)"
            >
              <div>
                {{ `$${tier.amount / 100}` }}
              </div>
            </div>
            <div
                class="cursor-pointer col-span-2 rounded-md text-center shadow-sm sm:text-md border text-gray-500 border-gray-500"
                @click="tierSelected(otherTier)">
              <template v-if="otherTier.selected">
                <money ref="money" v-bind="money" v-bind:value="otherTier.amount / 100"
                       @input="otherTier.amount = $event * 100"
                       class="py-3 px-4 block w-full rounded-md"
                       type="text"
                >
                </money>
              </template>
              <template v-else>
                <div class="p-3">
                  Other amount
                </div>
              </template>
            </div>
          </template>
        </div>
      </template>
      <div class="px-6 py-3"  v-if="total > 0 && feeTotal > 0">
          <div class="bg-gray-50 rounded-md py-2 px-4 mb-4 flex justify-between gap-x-4">
          <button @click="feeAddedToCart = !feeAddedToCart" type="button"
                :class="feeAddedToCart ? 'bg-teal-500' : 'bg-gray-200'"
                class="self-center relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500"
                role="switch"
                :aria-checked="feeAddedToCart">
          <span class="sr-only">Add fee to cart to cover payment processing fees</span>
          <!-- Enabled: "translate-x-5", Not Enabled: "translate-x-0" -->
          <span aria-hidden="true" :class="feeAddedToCart ? 'translate-x-5' : 'translate-x-0'"
                class="pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow ring-0 transition ease-in-out duration-200">
                </span>
        </button>
          <p class="text-xs">
          <template v-if="feeAddedToCart">
                  <span class="font-bold text-teal-600">{{ formatAsCurrency(feeTotal, 2) }} has been
                  added to cover processing fees</span><br/> Thank you! Your extra contribution means {{ teamName }}
            will receive
            100% of your <span class="font-medium" :style="{ color: primaryColor }">{{
              formatAsCurrency(total, 2)
            }}</span> contribution.
          </template>
          <template v-else>
            <span class="font-bold text-gray-900">Add {{ formatAsCurrency(feeTotal, 2) }} to cover processing fees?</span><br/>
            The extra contribution ensures {{ teamName }} receives 100% of your contribution.
          </template>
        </p>
        </div>
      </div>
      <ValidationObserver ref="observer" v-slot="{ handleSubmit } ">
        <div class="px-6 mb-8">
          <transition
              enter-active-class="transition ease-out duration-100"
              enter-class="opacity-0 translate-y-1"
              enter-to-class="opacity-0 translate-y-1"
              leave-active-class="transition ease-in duration-150"
              leave-class="opacity-100 translate-y-0"
              leave-to-class="opacity-0 translate-y-1"
          >
            <div role="form" class="" v-show="step === 'info'">
              <div class="flex justify-between items-center">
                <h2 class="text-base font-semibold leading-7 text-gray-900">Personal info</h2>
                <p class="text-sm font-normal text-gray-500">Step 1 of 2</p>
              </div>
              <div class="flex mt-2 flex-col space-y-4">
                <ValidationProvider name="first name" rules="required" v-slot="{ errors }">
                  <div>
                    <label for="first_name" class="block text-sm font-medium text-gray-700">First name</label>
                    <div class="mt-1 relative rounded-md shadow-sm">
                      <input type="text"
                             name="first name"
                             id="first_name"
                             v-model="firstName"
                             :class="inputClasses(errors[0])">
                      <error-icon v-if="errors[0]"></error-icon>
                    </div>
                    <p class="mt-2 text-sm text-red-600" v-if="errors[0]">
                      {{ errors[0] }}
                    </p>
                  </div>
                </ValidationProvider>
                <ValidationProvider name="last name" rules="required" v-slot="{ errors }">
                  <div>
                    <label for="last_name" class="block text-sm font-medium text-gray-700">Last name</label>
                    <div class="mt-1 relative rounded-md shadow-sm">
                      <input type="text"
                             name="last name"
                             id="last_name"
                             v-model="lastName"
                             :class="inputClasses(errors[0])">
                      <error-icon v-if="errors[0]"></error-icon>
                    </div>
                    <p class="mt-2 text-sm text-red-600" v-if="errors[0]">
                      {{ errors[0] }}
                    </p>
                  </div>
                </ValidationProvider>
                <div>
                  <label for="biz_name" class="block text-sm font-medium text-gray-700">{{ businessNameLabel }}</label>
                  <div class="mt-1 relative rounded-md shadow-sm">
                    <input type="text"
                           name="business name"
                           id="biz_name"
                           placeholder="Optional"
                           v-model="bizName"
                           :class="inputClasses()"
                    >
                  </div>
                </div>
                <ValidationProvider name="email" rules="required|email" :debounce="500" v-slot="{ errors }">
                  <div>
                    <label for="email" class="block text-sm font-medium text-gray-700">Email</label>
                    <div class="mt-1 relative rounded-md shadow-sm">
                      <input type="text"
                             name="email"
                             id="email"
                             v-model="email"
                             :class="inputClasses(errors[0])"
                      >
                      <error-icon v-if="errors[0]"></error-icon>
                    </div>
                    <p class="mt-2 text-sm text-red-600" v-if="errors[0]">
                      {{ errors[0] }}
                    </p>
                  </div>
                </ValidationProvider>
                <ValidationProvider name="phone" rules="required|digits:10" v-slot="{ errors }">
                  <div>
                    <label for="phone" class="block text-sm font-medium text-gray-700">Phone</label>
                    <div class="mt-1 relative rounded-md shadow-sm">
                      <the-mask mask="(###) ### - ####"
                                v-model="phone"
                                :class="inputClasses(errors[0])"
                                id="phone"
                                ref="phone"
                                type="tel">
                      </the-mask>
                      <error-icon v-if="errors[0]"></error-icon>
                    </div>
                    <p class="mt-2 text-sm text-red-600" v-if="errors[0]">
                      {{ errors[0] }}
                    </p>
                  </div>
                </ValidationProvider>
                <ValidationProvider name="address" rules="required" v-slot="{ errors }">
                  <div>
                    <label for="line_1" class="block text-sm font-medium text-gray-700">Street address</label>
                    <div class="mt-1 relative rounded-md shadow-sm">
                      <input type="text"
                             name="street address"
                             id="line_1"
                             v-model="addressLine1"
                             :class="inputClasses(errors[0])">
                      <error-icon v-if="errors[0]"></error-icon>
                    </div>
                    <p class="mt-2 text-sm text-red-600" v-if="errors[0]">
                      {{ errors[0] }}
                    </p>
                  </div>
                </ValidationProvider>
                <div class="grid grid-cols-8 space-x-2">
                  <div class="col-span-3">
                    <ValidationProvider name="city" rules="required" v-slot="{ errors }">
                      <div>
                        <label for="city" class="block text-sm font-medium text-gray-700">City</label>
                        <div class="mt-1 relative rounded-md shadow-sm">
                          <input type="text"
                                 name="city"
                                 id="city"
                                 v-model="city"
                                 :class="inputClasses(errors[0])">
                          <error-icon v-if="errors[0]"></error-icon>
                        </div>
                        <p class="mt-2 text-sm text-red-600" v-if="errors[0]">
                          {{ errors[0] }}
                        </p>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="col-span-2">
                    <ValidationProvider name="state" rules="required" v-slot="{ errors }">
                      <div>
                        <label for="state" class="block text-sm font-medium text-gray-700">State</label>
                        <div class="mt-1 relative rounded-md shadow-sm">
                          <input type="text"
                                 name="state"
                                 id="state"
                                 v-model="state"
                                 :class="inputClasses(errors[0])">
                          <error-icon v-if="errors[0]"></error-icon>
                        </div>
                        <p class="mt-2 text-sm text-red-600" v-if="errors[0]">
                          {{ errors[0] }}
                        </p>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="col-span-3">
                    <ValidationProvider name="zip" rules="required" v-slot="{ errors }">
                      <div>
                        <label for="zip" class="block text-sm font-medium text-gray-700">Zip</label>
                        <div class="mt-1 relative rounded-md shadow-sm">
                          <input type="text"
                                 name="zip"
                                 id="zip"
                                 v-model="zip"
                                 :class="inputClasses(errors[0])">
                          <error-icon v-if="errors[0]"></error-icon>
                        </div>
                        <p class="mt-2 text-sm text-red-600" v-if="errors[0]">
                          {{ errors[0] }}
                        </p>
                      </div>
                    </ValidationProvider>
                  </div>
                </div>
                <div v-for="(question, index) in metadata.additional_questions">
                  <label :for="'additional_question_' + index" class="block text-sm font-medium text-gray-700">{{ question.title }}</label>
                  <div class="mt-1 relative rounded-md shadow-sm">
                    <input type="text"
                           :name="question.title"
                           :id="'additional_question_' + index"
                           placeholder="Optional"
                           v-model="question.answer"
                           :class="inputClasses()"
                    >
                  </div>
                </div>
                <div>
                  <label for="first_name" class="block text-sm font-medium text-gray-700">Public comment</label>
                  <div class="mt-1 relative rounded-md shadow-sm">
                    <input type="text"
                           name="comment"
                           id="comment"
                           placeholder="Words of support for the team (optional)"
                           v-model="metadata.support_wall.comment"
                           class="shadow-sm block w-full border-gray-300 rounded-md sm:text-sm"
                           maxlength="144"
                    >
                  </div>
                </div>
                <div class="relative flex items-center mt-6">
                  <div class="flex h-6 items-center">
                    <input id="anonymous" name="anonymous" v-model="metadata.support_wall.anonymous" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-gray-700 focus:border-gray-500">
                  </div>
                  <div class="ml-3 text-sm leading-6 flex items-center space-x-1">
                    <label for="anonymous" class="text-gray-700">Don't display my name publicly on the fundraiser.</label>
                    <VDropdown theme="info-tooltip">
                      <!-- This will be the popover target (for the events and position) -->
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 text-blue-400">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z" />
                      </svg>
                      <!-- This will be the content of the popover -->
                      <template v-slot:popper>
                        <div>
                          <p>
                            Your name will be visible to the team admins, but not on the public wall of support.
                          </p>
                        </div>
                      </template>
                    </VDropdown>
                  </div>
                </div>
              </div>
            </div>
          </transition>
          <transition
              enter-active-class="transition ease-out duration-100"
              enter-class="opacity-0 translate-y-1"
              enter-to-class="opacity-0 translate-y-1"
              leave-active-class="transition ease-in duration-150"
              leave-class="opacity-100 translate-y-0"
              leave-to-class="opacity-0 translate-y-1"
          >
            <div id="billing-form" role="form" v-if="step === 'billing'" class="-mt-6">
              <div class="flex justify-between items-center">
                <h2 class="text-base font-semibold leading-7 text-gray-900">Billing</h2>
                <p class="text-sm font-normal text-gray-500">Step 2 of 2</p>
              </div>
              <div class="mt-2">
                <div v-if="useStripe" id="payment-element">
                  <!--Stripe.js injects the Payment Element-->
                </div>
                <div v-else-if="useJustifi">
                  <div class="flex mt-2 flex-col space-y-4">
                    <ValidationProvider name="name on card" rules="required" v-slot="{ errors }">
                      <div>
                        <label for="name_on_card" class="block text-sm font-medium text-gray-700">Name on card</label>
                        <div class="mt-1 relative rounded-md shadow-sm">
                          <input type="text"
                                 ref="nameoncard"
                                 name="name on card"
                                 id="name_on_card"
                                 v-model="nameOnCard"
                                 :class="inputClasses(errors[0])">
                          <error-icon v-if="errors[0]"></error-icon>
                        </div>
                        <p class="mt-2 text-sm text-red-600" v-if="errors[0]">
                          {{ errors[0] }}
                        </p>
                      </div>
                    </ValidationProvider>
                    <ValidationProvider name="zip" rules="required" v-slot="{ errors }">
                      <div>
                        <label for="zip" class="block text-sm font-medium text-gray-700">ZIP</label>
                        <div class="mt-1 relative rounded-md shadow-sm">
                          <input type="text"
                                 name="zip"
                                 id="zip"
                                 v-model="zip"
                                 :class="inputClasses(errors[0])">
                          <error-icon v-if="errors[0]"></error-icon>
                        </div>
                        <p class="mt-2 text-sm text-red-600" v-if="errors[0]">
                          {{ errors[0] }}
                        </p>
                      </div>
                    </ValidationProvider>
                    <template v-if="justifiLoading">
                      <wrestler-icon :stroke-color="primaryColor" class="mx-auto"></wrestler-icon>
                    </template>
                    <justifi-card-form @cardFormReady="justifiLoading = false" validation-mode="onBlur"></justifi-card-form>
                  </div>
                </div>
              </div>
            </div>
          </transition>
          <div class="mt-6">
            <recaptcha
                ref="recaptcha"
                :captcha-action="captchaAction"
                :captcha-site-key="captchaSiteKey"
                :captcha-v3-site-key="captchaV3SiteKey"
                style="margin-bottom: 16px; margin-top: 16px;"
            ></recaptcha>
            <button type="submit" v-if="step !== 'amount'"
                    @click.prevent="handleSubmit(finishStep)"
                    :disabled="totalIncludingFees === 0 || creatingContribution || fetchingPreview"
                    class="flex justify-evenly space-x-4 mt-2 focus:outline-none w-full py-3 rounded-md font-semibold"
                    :style="{backgroundColor: primaryColor, color: brandTextColor }">
              <span>Donate {{ readableTotal }}</span>
              <span v-if="creatingContribution || fetchingPreview || creatingToken">
              <wrestler-icon stroke-color="white"></wrestler-icon>
            </span>
            </button>
            <p v-if="error" class="text-red-500 text-xs text-center py-2">{{ errorMessage }}</p>
            <div class="text-center text-gray-500 text-xs">
              <p v-if="step === 'info'" class="mt-1">
                Next step: Enter your billing info
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-3 w-3 text-gray-500 inline">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3" />
                </svg>
              </p>
              <p v-if="step === 'billing'" class="mt-1">
                <svg class="h-3 w-3 text-gray-500 inline" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd"
                        d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                        clip-rule="evenodd"/>
                </svg>
                Secure payment processing provided by
                <span v-if="useStripe">Stripe</span><span v-else>WrestlingIQ</span><br/>
              </p>
              <p class="mt-4">
                <a target="_blank"
                   href="https://www.wrestlingiq.com?ref=donorboxv2">Powered by <span
                    class="underline text-emerald-500">WrestlingIQ</span></a>
              </p>
            </div>
          </div>
        </div>
      </ValidationObserver>
    </div>
    <div class="mt-4 p-6 text-center" v-else>
      <p class="text-gray-700 font-medium text-base">
        Fundraising for the {{ fundraiser.name }} has finished - thank you for the support!
      </p>
      <p class="text-xs mt-12">
        <a target="_blank" href="https://www.wrestlingiq.com?ref=fundraiser">Powered by
          <span class="underline text-emerald-500">WrestlingIQ</span>
        </a>
      </p>
    </div>
  </div>
</template>
<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { Money } from 'v-money';
import { TheMask } from 'vue-the-mask';
import { currencyMix } from '../shared/currency_mix';
import ErrorIcon from './error-icon.vue';
import WrestlerIcon from './wrestler-icon.vue';
import { recaptchaMix } from '../shared/recaptcha_mix.js';
import Recaptcha from '../shared/recaptcha.vue';
import { billingPartnerMix } from '../shared/billing_partner_mix';
import { errorableMix } from '../wrestling/vue/mixins/errorable_mix';

export default {
  name: 'contribute-form',
  mixins: [currencyMix, recaptchaMix, billingPartnerMix, errorableMix],
  components: {
    Recaptcha,
    WrestlerIcon,
    ErrorIcon,
    Money,
    ValidationProvider,
    ValidationObserver,
    TheMask,
  },
  props: {
    fundraiser: {
      type: Object,
      required: true,
    },
    campaign: {
      type: Object,
    },
    teamName: {
      type: String,
      required: true,
    },
    teamSlug: {
      type: String,
    },
    primaryColor: {
      type: String,
    },
    brandTextColor: {
      type: String,
      default: '#FFF'
    },
    headerText: {
      type: String,
    },
    // In cents, any out of band money raised to count to the bar (checks, etc)
    otherMoneyRaised: {
      type: Number,
      default: 0,
    },
    businessNameLabel: {
      type: String,
      default: "Business name"
    },
    currency: {
      type: String,
      default: 'usd',
    }
  },
  data() {
    return {
      money: {
        decimal: '.',
        thousands: ',',
        prefix: '$ ',
        suffix: '',
        precision: 0,
        masked: false,
      },
      loading: false,
      creatingContribution: false,
      customerId: null,
      fetchingPreview: false,
      cancelSource: null,
      invoicePreview: null,
      tiers: [],
      otherTier: {
        id: -1,
        amount: 75000,
        selected: false,
        currency: this.currency,
        purchase_quantity: 1,
      },
      firstName: '',
      lastName: '',
      bizName: '',
      email: '',
      phone: '',
      addressLine1: '',
      city: '',
      state: '',
      step: 'amount',
      feeAddedToCart: true,
      metadata: {
        additional_questions: [],
        support_wall: {
          anonymous: false,
          comment: "",
        }
      }
    };
  },
  mounted() {
    // Normalize our data structure for the frontend
    this.tiers = _.orderBy(_.filter(_.map(this.fundraiser.billing_plans, (t) => {
      t.selected = false;
      t.purchase_quantity = 1;
      return t;
    }), { active: true }), ['group_with', 'amount'], ['asc', 'desc']);
    if (!this.hasCustomTiers) {
      this.tierSelected(this.tiers[1]);
    }
    this.feeAddedToCart = this.fundraiser.fee_added_to_donor;
    // Let's parse the metadata so we can use reactivity when recording answers
    let additional_questions = [];
    _.map(this.fundraiser.metadata.additional_questions, (q) => {
        additional_questions.push({
          order: q.order,
          title: q.title,
          type: q.type,
          answer: ''
        })
    })
    // Sort the questions in ascending order
    additional_questions.sort(function(q1, q2) {
      return q1.order - q2.order
    });
    this.$set(this.metadata, 'additional_questions', additional_questions);
  },
  computed: {
    tiersByGroup() {
      if (this.hasCustomTiers) {
        return _.groupBy(this.tiers, 'group_with');
      }

      return { '': _.concat(this.tiers, this.otherTier) };
    },
    hasCustomTiers() {
      return !this.fundraiser.use_standard_tiers;
    },
    priceClasses: function () {
      return {
        'text-center': !this.hasCustomTiers,
      };
    },
    barWidthAndColor() {
      let amount = this.campaign ? this.campaign.amount_raised : this.fundraiser.amount_raised;
      amount = amount + this.otherMoneyRaised;
      const goal = this.campaign ? this.campaign.goal : this.fundraiser.goal;

      const width = amount > goal ? 100 : (amount / goal) * 100;
      return {
        width: `${width}%`,
        backgroundColor: this.primaryColor,
      };
    },
    bgColor() {
      return {
        backgroundColor: this.primaryColor,
      };
    },
    feeTotal() {
      if (this.invoicePreview) {
        return _.sum(_.map(this.invoicePreview.invoice_items, (ii) => {
          if (ii.transactable_type === 'ApplicationFee' || ii.description === "Contribution to cover card processing and WIQ fees") {
            return ii.amount;
          }

          return 0;
        }))
      }

      return 0;
    },
    selectedTiers() {
      let allTiers = _.concat(this.tiers, this.otherTier);
      return _.filter(allTiers, { selected: true });
    },
    selectedAndNotZeroQuantityTiers() {
      return _.filter(this.selectedTiers, function(t) { return t.purchase_quantity > 0 })
    },
    total() {
      if (this.invoicePreview) {
        return this.invoicePreview.amount_due - this.feeTotal;
      }

      return 0;
    },
    totalIncludingFees() {
      if (this.total === 0) {
        return 0;
      }
      return this.total + this.feeTotal;
    },
    readableTotal() {
      const totalInCents = this.feeAddedToCart ? this.totalIncludingFees : this.total;

      if (totalInCents === 0) {
        return '';
      }

      return this.formatAsCurrency(totalInCents, 2);
    },
    displayTitle() {
      if (this.headerText) {
        return this.headerText;
      }

      // Use campaign if specified, otherwise fall back to fundraiser
      if (this.campaign) {
        return `Support ${this.campaign.name}`;
      }

      return `Support ${this.teamName}`;
    },
    moneyRaised() {
      if (this.campaign) {
        return this.formatAsCurrency(this.campaign.amount_raised + this.otherMoneyRaised, 0);
      }

      return this.formatAsCurrency(this.fundraiser.amount_raised + this.otherMoneyRaised, 0);
    },
    goalAmount() {
      if (this.campaign) {
        return this.formatAsCurrency(this.campaign.goal, 0);
      }

      return this.formatAsCurrency(this.fundraiser.goal, 0);
    },
  },
  watch: {
    selectedAndNotZeroQuantityTiers() {
      if (this.selectedAndNotZeroQuantityTiers.length > 0) {
        this.previewContribution();
        // If we are at the very beginning, show the personal info form.
        if (this.step === 'amount') {
          this.finishStep();
        }
      }
    }
  },
  methods: {
    inputClasses(error) {
      const normalClasses = 'shadow-sm block w-full border-gray-300 rounded-md sm:text-sm';
      if (!error) {
        return normalClasses;
      }

      return `border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:ring-red ${normalClasses}`;
    },
    styleForTier(tier) {
      if (tier.selected) {
        return `border: 1px solid ${this.primaryColor}; color: ${this.primaryColor}; box-shadow: inset 0 0 0 1px ${this.primaryColor}`;
      } else if (tier.sold_out) {
        return 'border: 1px solid #d1d5db; color: #d1d5db;'
      }
    },
    tierSelected(tier) {
      // Check to make sure the tier isn't sold out
      if (tier.sold_out) {
        return false;
      }

      // First set them all to false if this is a 'standard' campaign
      if (!this.hasCustomTiers) {
        this.otherTier.selected = false;
        this.tiers = _.map(this.tiers, (t) => {
          t.selected = false;
          return t;
        });
      }

      // If we are not already selected, set it to true and set the quantity to 1
      tier.selected = !tier.selected;

      // If other is the other tier we selected
      // Focus up that input
      if (this.otherTier.selected) {
        this.$nextTick(function () {
          this.$refs.money.$el.focus();
        });
      }
    },
    finishStep() {
      if (this.step === 'amount') {
        this.step = 'info';
      } else if (this.step === 'info') {
        this.nameOnCard = `${this.firstName} ${this.lastName}`
        this.createCustomer();
      } else if (this.step === 'billing') {
        this.getPaymentToken(this.nameOnCard, this.email, this.zip);
      }
    },
    // We allow fee coverage to be passed in, because the preview endpoint
    // always uses it to calc fee to be added.
    params(customerId, feeCoverage, token) {
      const items = _.map(this.selectedAndNotZeroQuantityTiers, (t) => {
        return {
          id: t.id,
          type: "BillingPlan",
          quantity: t.purchase_quantity,
          amount: t.amount,
          interval: 'once'
        };
      });

      return {
        contribution: {
          customer_id: customerId,
          fee_coverage_added: feeCoverage,
          payment_method_token: token,
          items: items,
          metadata: this.metadata,
        }
      };
    },
    previewContribution() {
      const vm = this;
      const url = vm.$apiService.previewContributionV2Url(this.fundraiser.slug);
      // Cancel inflight requests
      if (vm.cancelSource) {
        vm.cancelSource.cancel();
      }
      vm.cancelSource = axios.CancelToken.source();
      vm.fetchingPreview = true;
      axios.post(url, vm.params(null,true, null), { cancelToken: vm.cancelSource.token })
          .then((response) => {
            vm.invoicePreview = response.data;
            vm.cancelSource = null;
            vm.fetchingPreview = false;
          })
          .catch((error) => {
            if (axios.isCancel(error)) {
              console.log('request cancelled');
            } else {
              vm.errorMessage = `Error retrieving contribution preview`;
              vm.error = true;
              vm.fetchingPreview = false;
            }
          });
    },
    tokenized(token) {
      this.createContribution(token);
    },
    createContribution(token) {
      const vm = this;
      const url = vm.$apiService.contributionV2Url(this.fundraiser.slug);
      if (vm.creatingContribution) {
        return;
      }
      vm.creatingContribution = true;
      axios.post(url, vm.params(vm.customerId,vm.feeAddedToCart, token))
          .then((response) => {
            vm.creatingContribution = false;
            const redirectUrl = this.successUrl ? this.successUrl : `${window.location.href.split('?')[0]}/success`;
            window.location.assign(redirectUrl);
          })
          .catch((error) => {
            // todo catch billing errors better?
            vm.errorMessage = `Error creating donation ${error.toString()}`;
            vm.error = true;
            vm.creatingDonation = false;
          });
    },
    async createCustomer() {
      const vm = this;

      if (vm.creatingContribution === true) {
        return;
      }

      vm.creatingContribution = true;
      vm.errorMessage = '';
      vm.error = false;

      // Step 1: Create a customer
      const customerParams = {
        'g-recaptcha-response-data': await vm.$refs.recaptcha.getCaptchaResponse(),
        customer: {
          email: vm.email,
          first_name: vm.firstName,
          last_name: vm.lastName,
          business_name: vm.bizName,
          phone_number: vm.phone,
          address_line_1: vm.addressLine1,
          city: vm.city,
          state: vm.state,
          zip: vm.zip,
        },
      };
      const url = vm.$apiService.customerUrl();
      axios.post(url, customerParams)
          .then((response) => {
            // Move to step 2: Billing Info
            vm.step = "billing";
            vm.customerId = response.data.id;
            vm.$forceNextTick(() => {
              // Your code here.
              vm.setupPaymentElement(response.data.setup_intent.client_secret, vm.primaryColor, "#374151", true);
              setTimeout(function () {
                document.getElementById("billing-form").scrollIntoView();
              }, 500);
            })
            vm.creatingContribution = false;
            vm.$refs.recaptcha.forceHideCaptcha();
          })
          .catch((error) => {
            vm.creatingContribution = false;
            if (vm.isRecaptchaError(error)) {
              vm.$refs.recaptcha.handleCaptchaFailed();
              vm.errorMessage = 'Recaptcha failed. Please solve and try again.';
            } else {
              vm.errorMessage = `Error, please try again later or contact support@wrestlingiq.com ${error.toString()}`;
            }
            vm.error = true;
          });
    },
  },
};
</script>
