import { render, staticRenderFns } from "./hosted_invoice_form.vue?vue&type=template&id=4539a820&scoped=true&"
import script from "./hosted_invoice_form.vue?vue&type=script&lang=js&"
export * from "./hosted_invoice_form.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4539a820",
  null
  
)

export default component.exports