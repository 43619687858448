<template>
  <div class="flex flex-col shadow-lg bg-white border border-gray-100 rounded-lg mt-16">
    <div class="p-6 text-center text-2xl font-bold rounded-t-lg"
         :style="{ backgroundColor: primaryColor, color: brandTextColor }">
      <h1>Donate to {{ teamName }}</h1>
    </div>
    <div>
      <div class="relative w-full h-1">
        <div class="absolute w-full h-full opacity-50" :style="{ backgroundColor: primaryColor }"></div>
      </div>
    </div>
    <div class="mt-4 grid gap-2 px-6 grid-cols-3">
      <template v-if="loading">
        <div class="mx-auto col-span-3">
          <wrestler-icon :stroke-color="primaryColor"></wrestler-icon>
        </div>
      </template>
      <template v-else-if="step !== 'donated'">
        <div class="w-full col-span-3 mx-auto flex items-center rounded-md">
          <button
              @click="changeInterval('once')"
              class="bg-white w-1/2 px-3 py-2 rounded-l-md text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
              :style="interval === 'once' ? {backgroundColor: primaryColor, color: brandTextColor } : {}">
            One-time
          </button>
          <button
              @click="changeInterval('monthly')"
              class="bg-white w-1/2 px-3 py-2 rounded-r-md text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
              :style="interval === 'monthly' ? {backgroundColor: primaryColor, color: brandTextColor } : {}">
            Monthly
          </button>
        </div>
        <div v-for="tier in tiersForCurrentInterval"
             v-bind:key="tier.id"
             class="cursor-pointer col-span-1 rounded-md p-3 shadow-sm sm:text-md border text-gray-500 border-gray-500 focus:ring-pink-500 focus:border-pink-500 text-center"
             :style="styleForTier(tier)"
             @click="tierSelected(tier)"
        >
          <div>
            {{ `$${tier.amount / 100}` }} <span v-if="tier.interval === 'monthly'">/mo</span>
          </div>
        </div>
        <div
            class="cursor-pointer col-span-2 rounded-md text-center shadow-sm sm:text-md border text-gray-500 border-gray-500"
            @click="tierSelected(otherTier)">
          <template v-if="otherTier.selected">
            <money ref="money" v-bind="money" v-bind:value="otherTier.amount / 100"
                   @input="otherTier.amount = $event * 100"
                   class="py-3 px-4 block w-full rounded-md"
                   type="text"
            >
            </money>
          </template>
          <template v-else>
            <div class="p-3">
              Other amount
            </div>
          </template>
        </div>
      </template>
    </div>
    <div class="px-6 py-3" v-if="total > 0 && feeTotal > 0 && step !== 'donated'">
      <div class="bg-gray-50 rounded-md py-2 px-4 flex justify-between gap-x-4">
        <button @click="feeAddedToCart = !feeAddedToCart" type="button"
                :class="feeAddedToCart ? 'bg-teal-500' : 'bg-gray-200'"
                class="self-center relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500"
                role="switch"
                :aria-checked="feeAddedToCart">
          <span class="sr-only">Add fee to cart to cover payment processing fees</span>
          <!-- Enabled: "translate-x-5", Not Enabled: "translate-x-0" -->
          <span aria-hidden="true" :class="feeAddedToCart ? 'translate-x-5' : 'translate-x-0'"
                class="pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow ring-0 transition ease-in-out duration-200">
                </span>
        </button>
        <p class="text-xs">
          <template v-if="feeAddedToCart">
                  <span class="font-bold text-teal-600">{{ formatAsCurrency(feeTotal, 2) }} has been
                  added to cover processing fees</span><br/> Thank you! Your extra contribution means {{ teamName }}
                  will receive 100% of your
            <span v-if="interval === 'monthly'">monthly</span>
            <span class="font-medium" :style="{ color: primaryColor }">{{
                formatAsCurrency(total, 2)
              }}</span> contribution.
          </template>
          <template v-else>
            <span class="font-bold text-gray-900">Add {{ formatAsCurrency(feeTotal, 2) }} to cover processing fees?</span><br/>
            The extra contribution ensures {{ teamName }} receives 100% of your contribution.
          </template>
        </p>
      </div>
    </div>
    <div>
      <ValidationObserver ref="observer" v-slot="{ handleSubmit } ">
        <div class="px-6 mt-8 pb-8">
          <transition
              enter-active-class="transition ease-out duration-100"
              enter-class="opacity-0 translate-y-1"
              enter-to-class="opacity-0 translate-y-1"
              leave-active-class="transition ease-in duration-150"
              leave-class="opacity-100 translate-y-0"
              leave-to-class="opacity-0 translate-y-1"
          >
            <div role="form" v-show="step === 'info'">
              <div class="flex justify-between items-center">
                <h2 class="text-base font-semibold leading-7 text-gray-900">Personal info</h2>
                <p class="text-sm font-normal text-gray-500">Step 1 of 2</p>
              </div>
              <div class="flex mt-2 flex-col space-y-4">
                <ValidationProvider name="first name" rules="required" v-slot="{ errors }">
                  <div>
                    <label for="first_name" class="block text-sm font-medium text-gray-700">First name</label>
                    <div class="mt-1 relative rounded-md shadow-sm">
                      <input type="text"
                             name="first name"
                             id="first_name"
                             v-model="firstName"
                             :class="inputClasses(errors[0])">
                      <error-icon v-if="errors[0]"></error-icon>
                    </div>
                    <p class="mt-2 text-sm text-red-600" v-if="errors[0]">
                      {{ errors[0] }}
                    </p>
                  </div>
                </ValidationProvider>
                <ValidationProvider name="last name" rules="required" v-slot="{ errors }">
                  <div>
                    <label for="last_name" class="block text-sm font-medium text-gray-700">Last name</label>
                    <div class="mt-1 relative rounded-md shadow-sm">
                      <input type="text"
                             name="last name"
                             id="last_name"
                             v-model="lastName"
                             :class="inputClasses(errors[0])">
                      <error-icon v-if="errors[0]"></error-icon>
                    </div>
                    <p class="mt-2 text-sm text-red-600" v-if="errors[0]">
                      {{ errors[0] }}
                    </p>
                  </div>
                </ValidationProvider>
                <div>
                  <label for="biz_name" class="block text-sm font-medium text-gray-700">Business name</label>
                  <div class="mt-1 relative rounded-md shadow-sm">
                    <input type="text"
                           name="business name"
                           id="biz_name"
                           placeholder="Optional"
                           v-model="bizName"
                           :class="inputClasses()"
                    >
                  </div>
                </div>
                <ValidationProvider name="email" rules="required|email" :debounce="500" v-slot="{ errors }">
                  <div>
                    <label for="email" class="block text-sm font-medium text-gray-700">Email</label>
                    <div class="mt-1 relative rounded-md shadow-sm">
                      <input type="text"
                             name="email"
                             id="email"
                             v-model="email"
                             :class="inputClasses(errors[0])"
                      >
                      <error-icon v-if="errors[0]"></error-icon>
                    </div>
                    <p class="mt-2 text-sm text-red-600" v-if="errors[0]">
                      {{ errors[0] }}
                    </p>
                  </div>
                </ValidationProvider>
                <ValidationProvider name="phone" rules="required|digits:10" v-slot="{ errors }">
                  <div>
                    <label for="phone" class="block text-sm font-medium text-gray-700">Phone</label>
                    <div class="mt-1 relative rounded-md shadow-sm">
                      <the-mask mask="(###) ### - ####"
                                v-model="phone"
                                :class="inputClasses(errors[0])"
                                id="phone"
                                ref="phone"
                                type="tel">
                      </the-mask>
                      <error-icon v-if="errors[0]"></error-icon>
                    </div>
                    <p class="mt-2 text-sm text-red-600" v-if="errors[0]">
                      {{ errors[0] }}
                    </p>
                  </div>
                </ValidationProvider>
                <ValidationProvider name="address" rules="required" v-slot="{ errors }">
                  <div>
                    <label for="line_1" class="block text-sm font-medium text-gray-700">Street address</label>
                    <div class="mt-1 relative rounded-md shadow-sm">
                      <input type="text"
                             name="street address"
                             id="line_1"
                             v-model="addressLine1"
                             :class="inputClasses(errors[0])">
                      <error-icon v-if="errors[0]"></error-icon>
                    </div>
                    <p class="mt-2 text-sm text-red-600" v-if="errors[0]">
                      {{ errors[0] }}
                    </p>
                  </div>
                </ValidationProvider>
                <div class="grid grid-cols-8 space-x-2">
                  <div class="col-span-3">
                    <ValidationProvider name="city" rules="required" v-slot="{ errors }">
                      <div>
                        <label for="city" class="block text-sm font-medium text-gray-700">City</label>
                        <div class="mt-1 relative rounded-md shadow-sm">
                          <input type="text"
                                 name="city"
                                 id="city"
                                 v-model="city"
                                 :class="inputClasses(errors[0])">
                          <error-icon v-if="errors[0]"></error-icon>
                        </div>
                        <p class="mt-2 text-sm text-red-600" v-if="errors[0]">
                          {{ errors[0] }}
                        </p>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="col-span-2">
                    <ValidationProvider name="state" rules="required" v-slot="{ errors }">
                      <div>
                        <label for="state" class="block text-sm font-medium text-gray-700">State</label>
                        <div class="mt-1 relative rounded-md shadow-sm">
                          <input type="text"
                                 name="state"
                                 id="state"
                                 v-model="state"
                                 :class="inputClasses(errors[0])">
                          <error-icon v-if="errors[0]"></error-icon>
                        </div>
                        <p class="mt-2 text-sm text-red-600" v-if="errors[0]">
                          {{ errors[0] }}
                        </p>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="col-span-3">
                    <ValidationProvider name="zip" rules="required" v-slot="{ errors }">
                      <div>
                        <label for="zip" class="block text-sm font-medium text-gray-700">Zip</label>
                        <div class="mt-1 relative rounded-md shadow-sm">
                          <input type="text"
                                 name="zip"
                                 id="zip"
                                 v-model="zip"
                                 :class="inputClasses(errors[0])">
                          <error-icon v-if="errors[0]"></error-icon>
                        </div>
                        <p class="mt-2 text-sm text-red-600" v-if="errors[0]">
                          {{ errors[0] }}
                        </p>
                      </div>
                    </ValidationProvider>
                  </div>
                </div>
              </div>
            </div>
          </transition>
          <transition
              enter-active-class="transition ease-out duration-100"
              enter-class="opacity-0 translate-y-1"
              enter-to-class="opacity-0 translate-y-1"
              leave-active-class="transition ease-in duration-150"
              leave-class="opacity-100 translate-y-0"
              leave-to-class="opacity-0 translate-y-1"
          >
            <div role="form" v-if="step === 'billing'" class="-mt-6">
              <div class="flex justify-between items-center">
                <h2 class="text-base font-semibold leading-7 text-gray-900">Billing</h2>
                <p class="text-sm font-normal text-gray-500">Step 2 of 2</p>
              </div>
              <div class="mt-2">
                <div v-if="useStripe" id="payment-element">
                  <!--Stripe.js injects the Payment Element-->
                </div>
                <div v-else-if="useJustifi">
                  <div class="flex mt-2 flex-col space-y-4">
                    <ValidationProvider name="name on card" rules="required" v-slot="{ errors }">
                      <div>
                        <label for="name_on_card" class="block text-sm font-medium text-gray-700">Name on card</label>
                        <div class="mt-1 relative rounded-md shadow-sm">
                          <input type="text"
                                 name="name on card"
                                 id="name_on_card"
                                 v-model="nameOnCard"
                                 :class="inputClasses(errors[0])">
                          <error-icon v-if="errors[0]"></error-icon>
                        </div>
                        <p class="mt-2 text-sm text-red-600" v-if="errors[0]">
                          {{ errors[0] }}
                        </p>
                      </div>
                    </ValidationProvider>
                    <ValidationProvider name="zip" rules="required" v-slot="{ errors }">
                      <div>
                        <label for="zip" class="block text-sm font-medium text-gray-700">ZIP</label>
                        <div class="mt-1 relative rounded-md shadow-sm">
                          <input type="text"
                                 name="zip"
                                 id="zip"
                                 v-model="zip"
                                 :class="inputClasses(errors[0])">
                          <error-icon v-if="errors[0]"></error-icon>
                        </div>
                        <p class="mt-2 text-sm text-red-600" v-if="errors[0]">
                          {{ errors[0] }}
                        </p>
                      </div>
                    </ValidationProvider>
                    <template v-if="justifiLoading">
                      <wrestler-icon :stroke-color="primaryColor" class="mx-auto"></wrestler-icon>
                    </template>
                    <justifi-card-form @cardFormReady="justifiLoading = false" validation-mode="onBlur"></justifi-card-form>
                  </div>
                </div>
              </div>
            </div>
          </transition>
          <transition
              enter-active-class="transition ease-out duration-100"
              enter-class="opacity-0 translate-y-1"
              enter-to-class="opacity-0 translate-y-1"
              leave-active-class="transition ease-in duration-150"
              leave-class="opacity-100 translate-y-0"
              leave-to-class="opacity-0 translate-y-1"
          >
            <div v-if="step === 'donated'" class="text-center">
              <h2 class="text-base font-semibold leading-7 text-gray-900">Thank you for your donation!</h2>
              <p class="block text-sm font-medium text-gray-700 mt-2">A receipt will be delivered to your inbox shortly 🤼</p>
            </div>
          </transition>
          <div class="mt-6">
            <recaptcha
                ref="recaptcha"
                :captcha-action="captchaAction"
                :captcha-site-key="captchaSiteKey"
                :captcha-v3-site-key="captchaV3SiteKey"
                style="margin-bottom: 16px; margin-top: 16px;"
            ></recaptcha>
            <button type="submit"
                    v-if="step !== 'donated'"
                    @click.prevent="handleSubmit(finishStep)"
                    :disabled="totalIncludingFees === 0 || creatingDonation || fetchingPreview || creatingToken"
                    class="flex justify-center space-x-4 mt-2 focus:outline-none w-full py-3 rounded-md font-semibold"
                    :style="{backgroundColor: primaryColor, color: brandTextColor }">
              <span>Donate {{ readableTotal }}</span>
              <span v-if="creatingDonation || fetchingPreview || creatingToken">
                <wrestler-icon stroke-color="white"></wrestler-icon>
              </span>
            </button>
            <p v-if="error" class="text-red-500 text-xs text-center py-2">{{ errorMessage }}</p>
            <div class="text-center text-gray-500 text-xs">
              <p v-if="step === 'info'" class="mt-1">
                Next step: Enter your billing info
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-3 w-3 text-gray-500 inline">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3" />
                </svg>
              </p>
              <p v-if="step === 'billing'" class="mt-1">
                <svg class="h-3 w-3 text-gray-500 inline" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd"
                        d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                        clip-rule="evenodd"/>
                </svg>
                Secure payment processing provided by
                <span v-if="useStripe">Stripe</span><span v-else>WrestlingIQ</span><br/>
              </p>
              <p class="mt-4">
                <a target="_blank"
                   href="https://www.wrestlingiq.com?ref=donorboxv2">Powered by <span
                    class="underline text-emerald-500">WrestlingIQ</span></a>
              </p>
            </div>
          </div>
        </div>
      </ValidationObserver>
    </div>
  </div>
</template>
<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { Money } from 'v-money';
import { TheMask } from 'vue-the-mask';
import { recaptchaMix } from '../shared/recaptcha_mix';
import { currencyMix } from '../shared/currency_mix';
import ErrorIcon from './error-icon.vue';
import WrestlerIcon from './wrestler-icon.vue';
import Recaptcha from '../shared/recaptcha.vue';
import { errorableMix } from '../wrestling/vue/mixins/errorable_mix';
import { billingPartnerMix } from '../shared/billing_partner_mix';

export default {
  name: 'donor-box-v2',
  mixins: [currencyMix, billingPartnerMix, recaptchaMix, errorableMix],
  components: {
    Recaptcha,
    WrestlerIcon,
    ErrorIcon,
    ValidationObserver,
    ValidationProvider,
    Money,
    TheMask
  },
  props: {
    forIframe: {
      type: Boolean,
      default: false
    },
    teamName: {
      type: String,
      required: true
    },
    primaryColor: {
      type: String,
      required: true,
    },
    brandTextColor: {
      type: String,
      default: '#FFF'
    },
    currency: {
      type: String,
      default: 'usd'
    },
    initialInterval: {
      type: String,
      default: 'once',
    }
  },
  data() {
    return {
      money: {
        decimal: '.',
        thousands: ',',
        prefix: '$ ',
        suffix: '',
        precision: 0,
        masked: false,
      },
      loading: false,
      creatingDonation: false,
      customerId: null,
      fetchingPreview: false,
      cancelSource: null,
      invoicePreview: null,
      subscriptionPreview: null,
      oneTimeTiers: [],
      recurringTiers: [],
      tiers: [],
      interval: 'once', // once or monthly
      otherTier: {
        id: -1,
        amount: 75000,
        selected: false,
        currency: this.currency,
      },
      firstName: '',
      lastName: '',
      email: '',
      bizName: '',
      phone: '',
      addressLine1: '',
      city: '',
      state: '',
      feeAddedToCart: true,
      metadata: {
        additional_questions: []
      },
      step: 'amount'
    };
  },
  watch: {
    selectedTier() {
      this.previewDonation();
      // If we are at the very beginning, show the personal info form.
      if (this.step === 'amount') {
        this.finishStep();
      }
    },
    otherTierAmount() {
      this.previewDonation();
    }
  },
  computed: {
    otherTierAmount() {
      return this.otherTier.amount;
    },
    tiersForCurrentInterval() {
      return _.filter(this.tiers, ['interval', this.interval]);
    },
    selectedTier() {
      if (this.otherTier.selected) {
        return this.otherTier;
      }
      return _.head(_.filter(this.tiers, ['selected', true]));
    },
    total() {
      if (this.invoicePreview) {
        return this.invoicePreview.amount_due - this.feeTotal;
      }

      if (this.subscriptionPreview) {
        return this.subscriptionPreview.total_per_interval - this.feeTotal;
      }

      return 0;
    },
    feeTotal() {
      if (this.invoicePreview) {
        return _.sum(_.map(this.invoicePreview.invoice_items, (ii) => {
          if (ii.transactable_type === 'ApplicationFee' || ii.description === "Contribution to cover card processing and WIQ fees") {
            return ii.amount;
          }

          return 0;
        }))
      }

      if (this.subscriptionPreview) {
        return _.sum(_.map(this.subscriptionPreview.plan_requests, (pr) => {
          if (pr.billing_plan.name === "WrestlingIQ Service Fee" || pr.billing_plan.name === "Contribution to cover card processing and WIQ fees") {
            return pr.billing_plan.amount;
          }

          return 0;
        }))
      }

      return 0;
    },
    totalIncludingFees() {
      if (this.total === 0) {
        return 0;
      }
      return this.total + this.feeTotal;
    },
    readableTotal() {
      const totalInCents = this.feeAddedToCart ? this.totalIncludingFees : this.total;

      if (totalInCents === 0) {
        return '';
      }

      const currencyFormat = this.formatAsCurrency(totalInCents, 2);
      return this.interval === 'monthly' ? `${currencyFormat} /month` : currencyFormat;
    },
  },
  mounted() {
    this.interval = this.initialInterval;
    this.loadTiers();
    // For iFrames it is hard to reliabily resize on events, particlarly validation
    // For now, just doing a loop to broadcast the new size to the parent frame.
    if (this.forIframe) {
      let vm = this;
      setInterval(() => {
        vm.broadcastResize();
      }, 1000);
    }
  },
  methods: {
    async loadTiers() {
      this.loading = true;
      await this.getOneTimeTiers();
      await this.getRecurringTiers()
      this.normalizeTiers();
      this.loading = false;
      this.broadcastResize();
    },
    async getRecurringTiers() {
      const vm = this;
      const url = '/api/team/v2/billing_plans?plan_type=donations-recurring';
      return axios.get(url)
          .then((response) => {
            vm.recurringTiers = response.data.plans;
          })
          .catch((error) => {
            vm.errorMessage = 'Error retrieving available recurring donation amounts';
            vm.error = true;
          });
    },
    async getOneTimeTiers() {
      const vm = this;
      const url = '/api/team/v2/billing_plans?plan_type=donations-once';
      return axios.get(url)
          .then((response) => {
            vm.oneTimeTiers = response.data.plans;
          })
          .catch((error) => {
            vm.errorMessage = 'Error retrieving available recurring donation amounts';
            vm.error = true;
          });
    },
    normalizeTiers() {
      const oneTimeTiers = _.map(this.oneTimeTiers, (t) => {
        t.selected = false;
        t.interval = 'once';
        return t;
      });
      const recurringTiers = _.map(this.recurringTiers, (t) => {
        t.selected = false;
        t.interval = 'monthly';
        return t;
      });
      const allTiers = _.concat(oneTimeTiers, recurringTiers);
      this.tiers = _.orderBy(_.filter(allTiers, { active: true }), ['amount', 'asc']);
    },
    changeInterval(newInterval) {
      if (newInterval === this.interval) {
        return;
      }

      this.interval = newInterval;

      // Handle switching
      if (!this.selectedTier) {
        return;
      }

      let currentAmount = this.selectedTier.amount;
      this.tiersForCurrentInterval.forEach((tier) => {
        if (tier.amount === currentAmount) {
          this.tierSelected((tier));
        }
      })

    },
    inputClasses(error) {
      const normalClasses = 'shadow-sm block w-full border-gray-300 rounded-md sm:text-sm';
      if (!error) {
        return normalClasses;
      }

      return `border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:ring-red ${normalClasses}`;
    },
    styleForTier(tier) {
      if (tier.selected) {
        return `border: 1px solid ${this.primaryColor}; color: ${this.primaryColor}; box-shadow: inset 0 0 0 1px ${this.primaryColor}`;
      } else if (tier.sold_out) {
        return 'border: 1px solid #d1d5db; color: #d1d5db;';
      }
    },
    tierSelected(tier) {
      this.otherTier.selected = false;
      this.tiers = _.map(this.tiers, (t) => {
        t.selected = false;
        return t;
      });

      // If we are not already selected, set it to true and set the quantity to 1
      tier.selected = !tier.selected;

      // If other is the other tier we selected
      // Focus up that input
      if (this.otherTier.selected) {
        this.$nextTick(function () {
          this.$refs.money.$el.focus();
        });
      }
    },
    // We allow fee coverage to be passed in, because the preview endpoint
    // always uses it to calc fee to be added.
    params(customerId, feeCoverage, token) {
      return {
        donation: {
          interval: this.interval,
          customer_id: customerId,
          fee_coverage_added: feeCoverage,
          payment_method_token: token,
          items: [
            {
              id: this.selectedTier.id,
              type: 'BillingPlan',
              quantity: 1,
              amount: this.selectedTier.amount,
              interval: this.selectedTier.interval
            }
          ],
          metadata: this.metadata,
        }
      };
    },
    finishStep() {
      if (this.step === 'amount') {
        this.step = 'info';
      } else if (this.step === 'info') {
        this.nameOnCard = `${this.firstName} ${this.lastName}`
        this.createCustomer();
      } else if (this.step === 'billing') {
        this.getPaymentToken(this.nameOnCard, this.email, this.zip);
      }
      this.$nextTick(() => {
        this.broadcastResize();
      });
    },
    broadcastResize() {
      let elRect = this.$el.getBoundingClientRect()
      window.parent.postMessage({
        type: 'resize-iframe',
        payload: {
          width: elRect.width,
          height: elRect.height
        }
      }, '*')
    },
    async createCustomer() {
      const vm = this;

      if (vm.creatingDonation === true) {
        return;
      }

      vm.creatingDonation = true;
      vm.errorMessage = '';
      vm.error = false;

      // Step 1: Create a customer
      const customerParams = {
        'g-recaptcha-response-data': await vm.$refs.recaptcha.getCaptchaResponse(),
        customer: {
          email: vm.email,
          first_name: vm.firstName,
          last_name: vm.lastName,
          business_name: vm.bizName,
          phone_number: vm.phone,
          address_line_1: vm.addressLine1,
          city: vm.city,
          state: vm.state,
          zip: vm.zip,
        },
      };
      axios.post('/api/team/v1/customers', customerParams)
          .then((response) => {
            // Move to step 2, billing
            // Step 2: Create the checkout session
            vm.step = "billing";
            vm.customerId = response.data.id;

            vm.$forceNextTick(() => {
              vm.setupPaymentElement(response.data.setup_intent.client_secret, vm.primaryColor, "#374151", true);
            });
            vm.creatingDonation = false;
            vm.$refs.recaptcha.forceHideCaptcha();
          })
          .catch((error) => {
            vm.creatingDonation = false;
            if (vm.isRecaptchaError(error)) {
              vm.$refs.recaptcha.handleCaptchaFailed();
              vm.errorMessage = 'Recaptcha failed. Please solve and try again.';
            } else {
              vm.errorMessage = `Error, please try again later or contact support@wrestlingiq.com ${error.toString()}`;
            }
            vm.error = true;
          });
    },
    tokenized(token) {
      this.createDonation(token);
    },
    createDonation(token) {
      const vm = this;
      if (vm.creatingDonation) {
        return;
      }
      vm.creatingDonation = true;
      axios.post('/api/team/v1/donations', vm.params(vm.customerId,vm.feeAddedToCart, token))
          .then((response) => {
            vm.creatingDonation = false;
            vm.step = "donated"
          })
          .catch((error) => {
            // todo catch billing errors better?
            vm.errorMessage = `Error creating donation ${error.toString()}`;
            vm.error = true;
            vm.creatingDonation = false;
          });
    },
    previewDonation() {
      const vm = this;
      const interval = this.interval;
      // Cancel inflight requests
      if (vm.cancelSource) {
        vm.cancelSource.cancel();
      }
      vm.cancelSource = axios.CancelToken.source();
      vm.fetchingPreview = true;
      axios.post('/api/team/v1/donations/preview', vm.params(null,true, null), { cancelToken: vm.cancelSource.token })
          .then((response) => {
            vm.invoicePreview = null;
            vm.subscriptionPreview = null;
            if (interval === 'once') {
              vm.invoicePreview = response.data;
            } else {
              vm.subscriptionPreview = response.data;
            }
            vm.cancelSource = null;
            vm.fetchingPreview = false;
          })
          .catch((error) => {
            if (axios.isCancel(error)) {
              console.log('request cancelled');
            } else {
              vm.errorMessage = `Error retrieving preview`;
              vm.error = true;
              vm.fetchingPreview = false;
            }
          });
    }
  }
};
</script>
