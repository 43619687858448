import Vue from 'vue/dist/vue.esm';

import axiosSetup from '../shared/api_key_axios_setup';
import validate from './config/validate.js';
import './styles/tailwind.css';
import './styles/tooltip.scss';
import TeamApiService from '../shared/team_api_service';
import ContributeForm from './contribute_form.vue';
import VueSocialSharing from 'vue-social-sharing';
import DonorBoxV2 from './donor_box_v2.vue';
import HostedInvoiceForm from './hosted_invoice_form.vue';
import VTooltip from 'v-tooltip';
import WallOfSupport from './wall_of_support.vue';

import VueForceNextTick from 'vue-force-next-tick'
Vue.use(VueForceNextTick);

// Sentry
import * as Sentry from '@sentry/browser';
import { Vue as VueIntegration } from '@sentry/integrations';

Vue.use(VueSocialSharing);
Vue.config.ignoredElements = ['trix-editor', 'justifi-card-form', 'justifi-billing-form', 'justifi-payment-method-form', 'justifi-payment-form', 'justifi-bank-account-form'];

Sentry.init({
  dsn: 'https://4b96b973744b4bf2ab5f5921513d01ca@sentry.io/256247',
  integrations: [new VueIntegration({ Vue, attachProps: true, logErrors: true })],
  environment: `${process.env.WIQ_ENV}`,
});

// Load the app
document.addEventListener('DOMContentLoaded', () => {
  // Setup axios
  axiosSetup();
  // Setup vee-validate
  validate();

  // Tooltips
  Vue.use(VTooltip, {
    themes: {
      'info-tooltip': {
        $extend: 'tooltip',
        triggers: ['hover', 'click'],
        popperTriggers: ['hover', 'click'],
        // Other options (see the 'Global options' section)
      },
    },
  });

  const api = new TeamApiService();
  Object.defineProperties(Vue.prototype, {
    $apiService: {
      get() {
        return api;
      },
    },
  });

  const app = new Vue({
    el: '#fundraiser-vue-wrapper',
    components: {
      ContributeForm,
      DonorBoxV2,
      HostedInvoiceForm,
      WallOfSupport,
    },
  });
});
