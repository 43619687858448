<template>
  <div v-if="!loading && contributions.length > 0" class="mt-24">
    <h2 class="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl mb-8">{{ title }}</h2>
    <div class="flex flex-col space-y-2">
      <div class="border border-gray-300 p-4 rounded-md bg-white shadow-sm" v-for="invoice in contributions" :key="invoice.id">
        <p class="font-medium text-gray-800"><span class="font-bold">{{ invoice.contributor_name }}</span> donated {{ formatAsCurrency(invoice.amount_paid) }}</p>
        <p v-if="invoice.comment" class="text-gray-700 mt-1">{{ invoice.comment }}</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'wall-of-support',
  props: {
    fundraiserSlug: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      loading: true,
      showAll: false,
      contributions: [],
    };
  },
  computed: {
    title() {
      if (this.contributions.length > 9) {
        return `Thank you to our ${this.contributions.length} supporters!`;
      }

      return 'Thank you to our supporters!';
    }
  },
  mounted() {
    let url = this.$apiService.contributionV2Url(this.fundraiserSlug);
    this.$apiService.loadAllPages(url, 'contributions')
        .then((contributions) => {
          this.contributions = contributions;
          this.loading = false;
        })
        .catch((error) => {
          console.error('Unable to load contributions', error);
        });
  },
  methods: {
    formatAsCurrency(amountInCents) {
      if (!amountInCents) {
        amountInCents = 0;
      }

      return (amountInCents / 100.00).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      });
    },
  }
};
</script>
